<template>
  <div class="login">
    <div class="login-box" v-if="!flag">
      <p style="color: #2961ff; font-size: 1.166667rem">欢迎登录</p>
      <p style="color: #ffffff; font-size: 1rem; margin-top: 0.4rem">
        智慧康养信息平台
      </p>
      <div class="userInput">
        <img
          src="@/assets/images/login_images/user.png"
          style="margin-right: 0.222rem"
        />
        <input
          type="text"
          v-model.trim="loginMessage.username"
          placeholder="请输入登录账号"
          maxlength="11"
          style="background: transparent; border: none; height: 0.916rem"
        />
      </div>
      <div class="userInput">
        <img
          src="@/assets/images/login_images/password.png"
          style="margin-right: 0.222rem"
        />
        <input
          type="password"
          v-model.trim="loginMessage.password"
          @keyup.enter="handleSubmit"
          v-if="show"
          placeholder="请输入登录密码"
        />
        <input
          type="text"
          v-model.trim="loginMessage.password"
          @keyup.enter="handleSubmit"
          v-if="!show"
          placeholder="请输入登录密码"
        />
        <img
          src="@/assets/images/login_images/hidden.png"
          @click="show = !show"
          v-if="show"
          style="position: absolute; right: 0.5rem; cursor: pointer"
        />
        <img
          src="@/assets/images/login_images/show.png"
          @click="show = !show"
          v-if="!show"
          style="position: absolute; right: 0.5rem; cursor: pointer"
        />
      </div>
      <div>
        <el-button
          :disabled="loginMessage.username == '' || loginMessage.password == ''"
          @click.native.prevent="handleSubmit"
          >立即登录</el-button
        >
      </div>
    </div>
    <div class="area-list" v-if="flag">
      <div style="" class="area-list-title">
        <span> 选择区域 </span>
        <img
          src="@/assets/images/login_images/changeOut.png"
          style="cursor: pointer"
          @click="handleLogout"
        />
      </div>
      <div class="area-list-content">
        <ul>
          <li
            v-for="item in areaList"
            :key="item.code"
            :class="activeCode == item.code ? 'active' : 'default'"
            @click="handleToPage(item)"
          >
            <img
              alt=""
              :src="
                activeCode == item.code ? list_computer : default_LIST_COMPUTER
              "
            />
            <span>{{ item.name }}</span>
            <img
              :src="activeCode == item.code ? RIGHT_IMG : RIGHT_DEFAULT_IMG"
              class="right"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import { switchTenant } from '@/api/user'
import { setTenantNo } from '@/utils/auth.js'

import list_computer from '@/assets/images/login_images/list_computer.png'
import default_LIST_COMPUTER from '@/assets/images/login_images/changeOutDefault.png'
import RIGHT_IMG from '@/assets/images/login_images/right.png'
import RIGHT_DEFAULT_IMG from '@/assets/images/login_images/rightDefault.png'

export default {
  data() {
    return {
      loginMessage: {
        username: '',
        password: '',
      },
      show: true,
      flag: false, //是否需要选择租户
      areaList: [], // 机构下拉数据
      list_computer: list_computer,
      default_LIST_COMPUTER: default_LIST_COMPUTER,
      RIGHT_DEFAULT_IMG: RIGHT_DEFAULT_IMG,
      RIGHT_IMG: RIGHT_IMG,
      activeCode: '',
    }
  },
  created() {
    let hasAreaList = JSON.parse(sessionStorage.getItem('hasAreaList'))
    if (hasAreaList) {
      this.flag = true
      this.areaList = hasAreaList
    }
    let activeCode = JSON.parse(sessionStorage.getItem('activeCode'))
    if (activeCode) {
      this.activeCode = activeCode
    }
    let username = this.getCryptoJSFun(
      sessionStorage.getItem('username'),
      'usernameKey'
    )
    let password = this.getCryptoJSFun(
      sessionStorage.getItem('password'),
      'passwordKey'
    )
    if (username && password && hasAreaList) {
      this.loginMessage.username = username
      this.loginMessage.password = password
    }
  },
  computed: {
    switch() {
      return this.$route.query.switch
    },
  },
  methods: {
    // 立即登录
    handleSubmit() {
      this.$store.dispatch('user/login', this.loginMessage).then((res) => {
        if (res.code == 0) {
          this.$router.push('/home')
        } else if (res.code == 60001) {
          this.areaList = res.data.map((item) => {
            return {
              name: item.name,
              code: item.areaCode,
              tenantNo: item.tenantNo,
            }
          })
          this.areaList = this.areaList.filter((item) => {
            return item.name
          })
          if (this.areaList.length == 0) {
            this.$message.error('该账号未有区域端大屏')
            return
          }
          this.flag = true
          sessionStorage.setItem('hasAreaList', JSON.stringify(this.areaList))
          sessionStorage.setItem(
            'username',
            this.cryptoJSFun(this.loginMessage.username, 'usernameKey')
          )
          sessionStorage.setItem(
            'password',
            this.cryptoJSFun(this.loginMessage.password, 'passwordKey')
          )
          // 解决websokiete进入没有连接的情况
          window.location.reload()
        }
      })
    },
    // 退出登录
    async handleLogout() {
      await this.$store.dispatch('user/toLoginPage')
      this.$router.push(`/login`)
      sessionStorage.removeItem('hasAreaList')
      this.flag = false
    },
    // 点击选择区域
    handleToPage(item) {
      this.activeCode = item.code
      sessionStorage.setItem('activeCode', JSON.stringify(item.code))
      setTenantNo(item.tenantNo)
      if (this.switch) {
        switchTenant(item.tenantNo).then((res) => {
          if (res.code == 0) {
            setTenantNo(res.data)
            // 在这里清空vuex中的身份标识，走permission全局路由后，回重新取获取到userInfo
            this.$store.dispatch('user/resetRole')
            this.$router.push('/home')
          }
        })
      } else {
        this.$store.dispatch('user/login', this.loginMessage).then((res) => {
          if (res.code == 0) {
            this.$router.push('/home')
          }
        })
      }
    },
    cryptoJSFun(value, valueKey) {
      let cipherText = CryptoJS.AES.encrypt(value, valueKey).toString()
      return cipherText
    },
    getCryptoJSFun(value, valueKey) {
      if (value) {
        let bytes = CryptoJS.AES.decrypt(value, valueKey)
        let cipherText = bytes.toString(CryptoJS.enc.Utf8)
        return cipherText
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';
</style>
